<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0  text-capitalize">Game Actions</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link to="/sessions">Sessions</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> Game Actions
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <data-table-wrapper
                    ref="dataTable"
                    :columns="columns"
                    :filters="filters"
                    :filter-defaults="filterDefaults"
                    :cell-style-option="cellStyleOption"
                    :on-data-load="onDataLoad"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    :endpoint="'/game-actions'"
                    api-service="analytics"
                    sort-column-default="timestamp"
                    sort-order-default="DESC"
                >
                    <template v-slot:filter-buttons>
                        <button class="btn btn-default float-right" @click="doExport">
                            <span v-if="!exportInProgress">
                                <i class="fa fa-cloud-download"></i> {{ $t('Export') }}
                            </span>
                            <span v-else>
                                <i class="fas fa-spinner fa-pulse"></i> {{ $t('Export') }}
                            </span>
                        </button>
                    </template>
                </data-table-wrapper>
            </div>

        </section>
    </div>
</template>

<script>
import * as formatUtils from '../helpers/formatUtils';
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from "vuex";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        DataTableWrapper,
        VueJsonPretty
    },
    data() {
        return {
            exportInProgress: false,
            onDataLoad: () => {
                let els = document.getElementsByClassName('vjs-tree');
                els.forEach((el) =>  {
                    if (el.firstElementChild.firstElementChild.firstElementChild.firstElementChild.firstElementChild.innerText === '{') {
                        el.firstElementChild.firstElementChild.firstElementChild.firstElementChild.firstElementChild.click();
                    }
                })
            },
            cellStyleOption: {
                bodyCellClass: ({ column }) => {
                    if (column.field === 'state' || column.field === 'sessionKey') {
                        return 'small-text';
                    }
                },
            },
            filters: [
                {
                    field: 'types',
                    placeholder: 'Select actions',
                    label: 'Actions',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (types) => { return types ? types.map(type => type.id) : [] },
                    getOptions: () => [
                        {id: 1, name: this.$t('action.1')},
                        {id: 2, name: this.$t('action.2')},
                        {id: 3, name: this.$t('action.3')},
                        {id: 4, name: this.$t('action.4')},
                        {id: 5, name: this.$t('action.5')},
                        {id: 6, name: this.$t('action.6')},
                        {id: 7, name: this.$t('action.7')},
                        {id: 8, name: this.$t('action.8')},
                        {id: 9, name: this.$t('action.9')},
                        {id: 10, name: this.$t('action.10')},
                        {id: 11, name: this.$t('action.11')},
                        {id: 12, name: this.$t('action.12')},
                    ],
                    trackBy: 'id'
                },
                {
                    field: 'sessionKey',
                    placeholder: 'Session Key',
                    label: 'Session Key',
                },
                {
                    field: 'id',
                    placeholder: 'ID',
                    label: 'ID',
                },
                {
                    field: 'roundId',
                    placeholder: 'Spin ID',
                    label: 'Spin ID',
                },
                {
                    field: 'transactionId',
                    placeholder: 'Transaction ID',
                    label: 'Transaction ID',
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'getGames',
            'getGame',
            'getPartners',
            'getPartner',
            'getOperators',
            'getOperator',
            'getCurrency',
            'isOperator',
        ]),
        columns() {
            let columns = [
                {
                    field: 'timestamp',
                    title: 'Date Time',
                    sorting: true,
                    isDate: true
                },
                {
                    field: 'type',
                    title: 'Action',
                    sorting: false,
                    callback: ({row}) => {
                        return this.$t('action.' + row.type)
                    },
                },
                {
                    field: 'isFreeSpin',
                    title: 'System Freespin',
                    sorting: false,
                    callback: ({row}) => {
                        if (row.isFreeSpin === null) {
                            return '';
                        }
                        if (row.isFreeSpin === true) {
                            return 'no'
                        }
                        return 'yes';
                    }
                },
                {
                    field: 'currency',
                    title: 'Currency',
                    sorting: false,
                },
                {
                    field: 'debitAmount',
                    title: 'Debit',
                    sorting: false,
                    callback: ({row}) => {
                        return row.debitAmount !== null ? formatUtils.formatNumberToFixed(row.debitAmount) : ''
                    },
                },
                {
                    field: 'creditAmount',
                    title: 'Credit',
                    sorting: false,
                    callback: ({row}) => {
                        return row.creditAmount !== null ? formatUtils.formatNumberToFixed(row.creditAmount) : ''
                    },
                },
                {
                    field: 'rollbackAmount',
                    title: 'Rollback',
                    sorting: false,
                    callback: ({row}) => {
                        return row.rollbackAmount !== null ? formatUtils.formatNumberToFixed(row.rollbackAmount) : ''
                    },
                },
                {
                    field: 'balance',
                    title: 'Balance',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.balance)
                    },
                },
                {
                    field: 'state',
                    title: 'Result',
                    sorting: false,
                    callback: ({row}) => {
                        return (
                            <vue-json-pretty data={row.state} deep={0} deepCollapseChildren={true}>
                            </vue-json-pretty>
                        );
                    },
                },
            ];

            if (!this.$route.params.sessionKey) {
                columns.unshift({
                    field: 'sessionKey',
                    title: 'Session Key',
                    sorting: false,
                });
            }
            columns.unshift({
                field: 'id',
                title: 'ID',
                sorting: false,
            });
            return columns;
        },
        filterDefaults() {
            return {
                sessionKey: this.$route.params.sessionKey
            }
        }
    },
    methods: {
        doExport() {
            if (this.exportInProgress) {
                return;
            }
            this.exportInProgress = true;
            this.$exportService.doExport('game-actions', this.$refs.dataTable.getPageQuery()).finally(() => {
                this.exportInProgress = false;
            });
        },
    },
    mounted() {
    },
};
</script>
