<template>
    <div>
        <div v-if="filters && filters.length" class="row col-lg-12">
            <div class="col-lg-12 col-md-12 col-sm-12 apply-clear">
                <div class="form-group">
                    <button class="btn btn-primary" @click="this.loadData">
                        <i class="fa fa-play"></i>&nbsp;{{ $t('Search') }}
                    </button>
                    &nbsp;

                    <button class="btn btn-default" @click="resetFilters">
                        {{ $t('Reset') }}
                    </button>
                    &nbsp;

                    <b-button
                        :pressed="showAdvancedFilter"
                        title="Toggle advanced filter"
                        variant="outline-secondary"
                        @click="toggleAdvancedFilter">
                        <i class="fa fa-sliders-h"></i>&nbsp;{{ $t('Advanced') }}
                    </b-button>

                    <slot name="filter-buttons"></slot>
                </div>
            </div>
        </div>

        <advanced-filters
            v-if="showAdvancedFilter"
            :advanced-filters="filters"
            :filters="outputFilters"
        ></advanced-filters>

        <div id="loading-container" class="row col-lg-12">
            <div class="col-12">
                <ve-table :columns="outputColumns"
                          :cell-style-option="cellStyleOption"
                          :fixed-header="true"
                          :sort-option="sortOption()"
                          :table-data="items"
                          class="text-nowrap"
                />
                <br/>

                <div class="row">
                    <div class="col-6">
                    </div>
                    <div class="col-6">
                        <ve-pagination
                            :layout="['total', 'prev', 'pager', 'next']"
                            :page-index="pagination.page"
                            :page-size="pagination.onPage"
                            :total="pagination.total"
                            class="float-right"
                            @on-page-number-change="onPageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {formatDatetime} from '../helpers/easytableCellFormats';
import AdvancedFilters from '../components/AdvancedFilters';
import Vue from "vue";

const ALIGN_DEFAULT = 'left';
const ONPAGE_DEFAULT = 20;
const SORT_COLUMN_DEFAULT = 'id';
const SORT_ORDER_DEFAULT = 'DESC';

export default {
    components: {
        AdvancedFilters,
    },
    props: [
        'cellStyleOption',
        'columns',
        'filters',
        'loadDataCallback',
        'endpoint',
        'apiService',
        'onPage',
        'onDataLoad',
        'disableEditAction',
        'disableDeleteAction',
        'sortColumnDefault',
        'sortOrderDefault',
        'disablePrimaryColumn',
        'filterDefaults',
        'actionButtons',
        'showFilters',
    ],
    data() {
        return {
            loadingInstance: null,

            showEmpty: false,
            showAdvancedFilter: false,

            outputColumns: [],
            outputFilters: {},
            items: [],
            pagination: {
                page: 1,
                total: 0,
                onPage: ONPAGE_DEFAULT,
            },
            sorts: {
                column: SORT_COLUMN_DEFAULT,
                order: SORT_ORDER_DEFAULT,
            },
        };
    },
    methods: {
        getFilter(field) {
            return this.filters.find(filter => filter.field === field);
        },
        getFilterValue(field) {
            let filter = this.filters.find(filter => filter.field === field);
            if (filter && filter.getValue) {
                return filter.getValue(this.outputFilters[field]);
            } else {
                return this.outputFilters[field];
            }
        },
        setFilterValue(field, value) {
            this.outputFilters[field] = value;
        },
        loadData(payload) {
            if (!payload) {
                payload = {}
            }
            let loadingInstance = this.$veLoading({
                target: document.querySelector('#loading-container'),
                name: 'wave',
            });

            let url = this.endpoint;
            if (this.apiService === 'analytics') {
                url = Vue.config.analyticsURL + url;
            }
            const params = this.getPageQuery();

            if (!payload.silent) {
                loadingInstance.show();
            }


            const { href } = this.$router.resolve({query: params});
            window.history.replaceState({}, null, href);
            this.$http({
                url,
                method: 'GET',
                params,
            }).then(resp => {
                if (this.loadDataCallback) {
                    this.loadDataCallback(this, resp.data);
                    return;
                }
                this.items = resp.data.items;
                this.pagination.onPage = resp.data.pagination.limit;
                this.pagination.total = resp.data.pagination.totalCount;

                if (this.items.length === 0) {
                    this.showEmpty = true;
                }
                if (this.onDataLoad) {
                    this.onDataLoad(this);
                }
            }).catch((err) => {
                console.log(err);
                this.items = [];
            }).finally(() => {
                if (!payload.silent) {
                    loadingInstance.close();
                }
            });
        },
        onPageChange(page) {
            this.pagination.page = page;
            this.loadData();
        },
        getPageQuery() {
            let pageQuery = {
                // ordering
                orderBy: this.sorts.column,
                direction: this.sorts.order,
                // pagination
                limit: this.pagination.onPage,
                offset: this.pagination.onPage * (this.pagination.page - 1),
            };
            for (let filterKey in this.outputFilters) {
                pageQuery[filterKey] = this.getFilterValue(filterKey);
            }

            return pageQuery;
        },
        sortChange: (params) => {
            let sort = null;

            for (let i in params) {
                if (params[i] !== '') {
                    sort = {
                        column: i,
                        order: params[i],
                    };
                }
            }

            if (sort) {
                this.sorts = sort;
            }

            this.page = 1;
            this.loadData();
        },
        sortOption() {
            return {
                sortChange: (params) => {
                    for (let i in params) {
                        if (params[i] !== '') {
                            this.sorts.column = i;
                            this.sorts.order = params[i];
                        }
                    }

                    this.pagination.page = 1;
                    this.loadData();
                },
            };
        },
        prepareInputData() {
            if (this.onPage) {
                this.pagination.onPage = this.onPage;
            }
            if (this.sortColumnDefault) {
                this.sorts.column = this.sortColumnDefault;
            }
            if (this.sortOrderDefault) {
                this.sorts.order = this.sortOrderDefault;
            }
        },
        prepareColumns() {
            if (!this.disablePrimaryColumn) {
                this.outputColumns.push({
                    field: 'id',
                    title: 'ID',
                    align: ALIGN_DEFAULT,
                    sortBy: 'id',
                });
            }

            this.columns.forEach(value => {
                if (!value.field) {
                    throw new Error('Column `field` is required');
                }
                if (!value.title) {
                    throw new Error('Column `title` is required');
                }

                let columnData = {
                    field: value.field,
                    title: value.title,
                    align: value.align ?? ALIGN_DEFAULT,
                    sortBy: value.sorting ? value.field : null,
                };
                if (value.isDate) {
                    columnData.renderBodyCell = ({row}) => formatDatetime(row[value.field] ?? '');
                } else if (value.callback) {
                    columnData.renderBodyCell = value.callback;
                }

                this.outputColumns.push(columnData);
            });

            if (!this.disableEditAction || !this.disableDeleteAction) {
                this.outputColumns.push({
                    field: 'actions',
                    title: 'Actions',
                    align: 'right',
                    renderBodyCell: ({row}) => {
                        let buttonsHtml = [];

                        if (this.actionButtons) {
                            this.actionButtons.forEach((btnFn) => {
                                buttonsHtml.push(btnFn(row))
                            })
                        }

                        if (!this.disableEditAction) {
                            buttonsHtml.push(
                                <span>&nbsp;</span>
                            )
                            buttonsHtml.push(
                                <span class="btn btn-primary btn-xs" onClick={() => this.$emit('edit-request', row)}><i
                                    class="fas fa-edit"></i>&nbsp;Edit</span>
                            );
                        }
                        if (!this.disableDeleteAction) {
                            buttonsHtml.push(
                                <span>&nbsp;</span>
                            )
                            buttonsHtml.push(
                                <span class="btn btn-danger btn-xs" onClick={() => this.$emit('delete-request', row)}><i
                                    class="fas fa-trash"></i>&nbsp;Delete</span>
                            );
                        }

                        return <div class="right">{buttonsHtml}</div>;
                    },
                });
            }
        },
        toggleAdvancedFilter() {
            this.showAdvancedFilter = !this.showAdvancedFilter;
        },
        setFiltersFromRoute() {
            for (let paramName in this.$route.query) {
                let filter = this.getFilter(paramName);

                switch (paramName) {
                    case 'orderBy':
                        this.sorts.column = this.$route.query[paramName];
                        break;
                    case 'direction':
                        this.sorts.order = this.$route.query[paramName];
                        break;
                    case 'limit':
                        this.pagination.onPage = parseInt(this.$route.query[paramName]);
                        break;
                    case 'offset':
                        this.pagination.page = parseInt(this.$route.query[paramName]) / parseInt(this.$route.query.limit) + 1
                        break;
                    default:
                        if (!filter) {
                            break;
                        }
                        if (filter.getOptions) {
                            let trackBy = filter.trackBy;
                            if (!this.$route.query[paramName]) {
                                break;
                            }
                            let queryValues = this.$route.query[paramName];
                            if (!Array.isArray(queryValues)) {
                                queryValues = [queryValues];
                            }

                            let value = filter.multiple
                                ? filter.getOptions().filter(item => queryValues.includes(item[trackBy]))
                                : filter.getOptions().find(item => item[trackBy] === queryValues[0]);

                            Vue.set(this.outputFilters, paramName, value);
                            filter.onSelect ? filter.onSelect(value) : null;
                        } else {
                            Vue.set(this.outputFilters, paramName, this.$route.query[paramName]);
                        }
                        break;
                }
            }
        },
        resetFilters() {
            this.outputFilters = {};
            if (this.filterDefaults) {
                for (let field in this.filterDefaults) {
                    Vue.set(this.outputFilters, field, this.filterDefaults[field]);
                }
            }
        },
    },
    mounted() {
        this.showAdvancedFilter = this.showFilters === true
        this.resetFilters();
        this.setFiltersFromRoute();
        this.prepareInputData();
        this.prepareColumns();
        this.loadData();
    },
};
</script>
