<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-capitalize">Advanced</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> Advanced
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div id="loading-container" class="row col-lg-12">
        <div class="col-12 ve-table">
          <div class="container-fluid ve-table-container ve-table-border-around">
            <table class="ve-table-content ve-table-border-x">
              <thead class="ve-table-fixed-header ve-table-header">
              <tr class="ve-table-header-tr">
                <th rowspan="1" colspan="1" class="ve-table-header-th">Rule</th>
                <th rowspan="1" colspan="1" class="ve-table-header-th ">Date</th>
                <th rowspan="1" colspan="1" class="ve-table-header-th text-center">Status</th>
              </tr>
              </thead>
              <tbody class="ve-table-body ve-table-row-hover ve-table-row-highlight">
              <tr v-for="rule in sortedRules" :key="rule.id" class="ve-table-body-tr">
                <td rowspan="1" colspan="1" class="ve-table-body-td">
                  <strong>{{ rule.title }}</strong>
                  <div v-for="(message, i) in getCheckResultMessages(rule.id)" :key="i">
                    {{ message }}
                  </div>
                </td>
                <td rowspan="1" colspan="1" class="ve-table-body-td">
                  {{ getCheckResultDate(rule.id) }}
                </td>
                <td v-if="checkingInProgress" rowspan="1" colspan="1" class="ve-table-body-td">
                  <i class="fa fa-spin fa-spinner"></i> Checking
                </td>
                <td v-else rowspan="1" colspan="1" class="ve-table-body-td text-center" :class="getCheckResultClass(rule.id)">
                  {{ getCheckResult(rule.id).status }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.bg-success {
  background-color: #97d6a6 !important;
}

.bg-danger {
  background-color: #e3858e !important;
}
</style>
<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import * as formatUtils from '../helpers/formatUtils';

export default {
  components: {
  },
  data() {
    return {
      rules: [{
        "id": "match-transactions",
        "title": "Checking match transactions"
      }],
      checkResults: [],
      checkingInProgress: false
    }
  },
  computed: {
    ...mapGetters([]),
    sortedRules() {
      return this.rules;
    }
  },
  methods: {
    getCheckResultClass(id) {
      let result = this.getCheckResult(id);
      if (!result) {
        return {};
      }
      return {
        'bg-danger': result.status === 'fail',
        'bg-success': result.status === 'success',

      }
    },
    getCheckResultDate(id) {
      let result = this.getCheckResult(id);
      if (!result) {
        return '';
      }
      return formatUtils.formatDatetime(result.date)
    },
    getCheckResultMessages(id) {
      let result = this.getCheckResult(id);
      if (!result) {
        return [];
      }
      return result.messages;
    },
    getCheckResult(id) {
      return this.checkResults[id] ?? null;
    },
    loadData() {
      this.checkingInProgress = true;
      let loadingInstance = this.$veLoading({
        target: document.querySelector("#loading-container"),
        name: "wave",
      });
      loadingInstance.show();

      Promise.all([
        this
          .$http({
            url: Vue.config.analyticsURL + '/advanced',
            method: 'GET',
          })
          .then(resp => {
            this.checkResults = resp.data;
          })
          .finally(() => {
            loadingInstance.close();
          })
      ]).finally(() => {
        this.checkingInProgress = false;
      })
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>
